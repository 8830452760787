'use strict';
/**
 * The creation/edition category page
 * @param {Object} $scope - the local scope object
 * @param {Object} $state - the state object
 * @param {Object} DataManager - the DataManager API
 * @param {Object} Notification - The Notification util
 */
angular.module('cpformplastApp.items')
  .controller('categoryCreationController', function($scope, $state, DataManager, Notification) {

    $scope.types = [
      'HIPS',
      'PET',
      'PVC',
      'AUTRE'
    ];
    $scope.category = {};
    $scope.submitted = false;

    /**
     * Initialisation function
     * If we update, fetch the category with his id
     * otherwise we create a new one
     */
    $scope.init = function(){
      if (!$state.params.categoryId)
        return;
      DataManager.fetchCategory($state.params.categoryId)
        .then(category => $scope.category = category)
        .catch(error => console.log(error));
    };

    /**
     * Validate the category submission
     * @param {Boolean} archive - indicates if you want to archive category or not
     * @param {Object} form - the category form
     */
    $scope.archive = function(archive, form){
      DataManager.archiveCategory($scope.category._id, archive)
      .then((data) => {
        $scope.category.archive = archive;
        const msp = archive ? 'archivée' : 'réinitialisée';
        Notification.success(`Le matériel <strong>${$scope.category.name}</strong> fut ${msp}`);
      })
      .catch(err => {
        Notification.error('Un problème est survenu');
      });
    };

    /**
     * Validate the category submission
     * @param {Object} form - the category form
     * @param {Boolean} create - if 'true' go to a new category creation page
     * @param {Object} message - to customize the output message
     * @return {Boolean}
     */
    $scope.submitCategory = function(form, create, message = "enregistrée") {

      //Initialisation
      $scope.submitted = true;
      if (!form.$valid){ return false; }
      let promise = !$scope.category._id ? DataManager.createCategory($scope.category):
                                           DataManager.updateCategory($scope.category);
      //Success
      promise.then(data => {
        Notification.success(`La categorie <strong> ${$scope.category.title}</strong> fut ${message}`);
        if (create) {
          $state.go('items/category-management/category-creation',{'categoryId' : ''}, {reload:true});
        } else {
          $state.go('items/category-management/category-creation', {'categoryId' : data._id}, {reload:true});
        }

      })
       //Failure
      .catch(err => {
        console.log(err);
        if (err.data.err.message) {
          Notification.error(err.data.err.message);
        } else {
          Notification.error('Un problème est survenu');
        }
      });

    };

    //INIT
    $scope.init();
  });
